<template>
  <v-card
    outlined
    style="display: inline-block;"
  >
    <v-list
      dense
      disabled
    >
      <v-list-item-group>
        <v-list-item
          v-for="l in labels"
          :key="l"
        >
          <v-list-item-avatar
            tile
            size="16"
            :color="color(l)"
            class="my-0 mr-3"
          />

          <v-list-item-content class="py-0">
            <v-list-item-title v-text="l"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import chartPropsMixin from '@/utils/mixins/charts/chartProps.mixin'
import styleMixin from '@/utils/mixins/styling/style.mixin'

export default {
  name: 'pibot-chart-legend',
  mixins: [chartPropsMixin, styleMixin],
  props: {
    labels: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <pibot-chart-container>
    <template #title>
      <slot name="title">Pie Chart</slot>
    </template>

    <template #title-append>
      <slot name="title-append" />
    </template>

    <div>
      <slot />
      <div :class="wrapperClass">
        <pibot-chart-legend :labels="labels || computedLabels" :colorScheme="colorScheme" :orient="orient" />
        <div class="d-flex justify-center w-100">
          <div :id="`chart-container-${randomID}`" class="chart-container"></div>
        </div>
      </div>
    </div>
  </pibot-chart-container>
</template>

<script>
import * as d3 from 'd3'
import ChartWrapper from './Chart'
import ChartLegend from './Legend'
import styleMixin from '@/utils/mixins/styling/style.mixin'
import chartLegendMixin from '@/utils/mixins/charts/chartLegend.mixin'
import chartPropsMixin from '@/utils/mixins/charts/chartProps.mixin'
import chartTooltipMixin from '@/utils/mixins/charts/chartTooltip.mixin'

export default {
  name: 'pibot-chart-pie',
  mixins: [styleMixin, chartLegendMixin, chartPropsMixin, chartTooltipMixin],
  components: {
    'pibot-chart-container': ChartWrapper,
    'pibot-chart-legend': ChartLegend
  },
  props: {
    data: {
      type: Object,
      default: () => ({ a: 9, b: 20, c: 30, d: 8, e: 12 }) // DUMMY DATA
    },
    donut: {
      type: Boolean,
      default: false
    },
    'show-labels': {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * Calculate chart margins.
     * 10% of the width or height, depending on largest value.
     */
    margin () {
      const wMargin = (this.width / 100) * 10
      const hMargin = (this.height / 100) * 10
      return wMargin > hMargin ? wMargin : hMargin
    },
    radius () {
      return Math.min(this.width, this.height) / 2 - this.margin
    },
    innerRadius () {
      return this.donut ? this.margin * 1.5 : 0
    },
    computedLabels () {
      return Object.keys(this.data)
    },
    totalValue () {
      // Sum all numbers in 'data'
      return Object.values(this.data).filter(v => !isNaN(v)).reduce((b, a) => b + a, 0)
    }
  },
  mounted () {
    // Append the svg object to the DOM
    const svg = d3.select(`#chart-container-${this.randomID}`)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('max-width', '100%')
      .style('max-height', '100%')
      .attr('viewBox', `0 0 ${Math.max(this.width, this.height)} ${Math.min(this.width, this.height)}`)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .append('g')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)

    // Compute the position of each group on the pie:
    const pie = d3.pie()
      .value((d) => d[1])
    const dataReady = pie(Object.entries(this.data))

    const arc = d3.arc()

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    const chart = svg.selectAll('whatever')
      .data(dataReady)
      .enter()
      .append('path')
      .attr('d', arc
        .innerRadius(this.innerRadius) // This is the size of the donut hole
        .outerRadius(this.radius)
      )
      .attr('class', 'pie-chart-path')
      .attr('fill', (d) => this.color(d.data[0]))
      .attr('stroke', (this.donut) ? 'white' : 'black')
      .attr('stroke-width', (this.donut) ? 6 : 1)

    if (this.showLabels) {
      const labels = svg.append('g')
        .attr('class', 'inner-pie-labels')
        .selectAll('text')
        .data(pie(Object.entries(this.data)))
        .enter()

      labels
        .append('text')
        .attr('class', 'inner-pie-label inner-pie-label-percent text-subtitle-2')
        .attr('transform', d => `translate(${arc.centroid(d)})`)
        .attr('x', -(this.radius * 0.10625))
        // .attr('y', -10)
        // .attr('y', -this.innerRadius / this.radius)
        .style('fill', (d) => this.lightOrDark(this.color(d.data[0])) === 'light' ? '#000' : '#fff')
        .style('pointer-events', 'none')
        .text(d => `${Math.round(100 * (d.value / this.totalValue))}%`)

      // labels
      //   .append('text')
      //   .attr('class', 'inner-pie-label inner-pie-label-value text-subtitle-2')
      //   .attr('transform', d => `translate(${arc.centroid(d)})`)
      //   .attr('x', -(this.innerRadius / this.radius) * 87.375)
      //   .attr('y', (this.innerRadius / this.radius) + 16) // 1rem = 16px
      //   .style('fill', (d, i) => i === 2 ? '#fff' : '#000')
      //   .text(d => `€${d3.format(',.2r')(d.value)}`)
    }

    if (this.useTooltip) {
      this.initTooltip(this.randomID)
      chart.on('mouseover', this.mouseover)
        .on('mousemove', this.mousemove)
        .on('mouseleave', this.mouseleave)
    }
  }
}
</script>

<!-- DEPRECATED -->
<template>
  <v-card
    outlined
    style="height:100%"
  >
    <v-card-title>
      <v-row align="end">
        <v-col :cols="12" :md="$slots['title-append'] ? 8 : 12">
          <slot name="title">Chart</slot>
        </v-col>

        <v-col
          v-if="$slots['title-append']"
          :cols="12"
          :md="4"
          class="text-body-2 grey--text"
          :class="{'text-right': $vuetify.breakpoint.mdAndUp}"
        >
          <slot name="title-append" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text class="pibot-chart-container">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-chart-container'
}
</script>

export default {
  props: {
    useLegend: {
      type: Boolean,
      default: true
    },
    orient: {
      type: String,
      default: 'top',
      validator: (v) => {
        // The value must match one of these strings
        return ['top', 'right', 'bottom', 'bottom-right'].includes(v)
      }
    }
  },
  computed: {
    reverseFlex () {
      return this.orient.includes('right')
    },
    onBottom () {
      return this.orient.includes('bottom')
    },
    wrapperClass () {
      return {
        'd-md-flex': true,
        'justify-space-between': true,
        'align-end': this.onBottom,
        'align-start': !this.onBottom,
        'flex-row-reverse': this.reverseFlex
      }
    }
  },
  methods: {
    createLegendCssLine (id, color, index) {
      return `#${id} .ct-legend .ct-series-${index}:before {background-color: ${color} !important;}`
    },
    setLegendCSS (css) {
      const refId = 'ct-legend-style'

      // Get the document's <head>
      const head = document.head || document.getElementsByTagName('head')[0]

      // Get the existing <style> or create a new one
      const styleElem = document.getElementById(refId) || document.createElement('style')

      // If the <style> does not yet exist in the DOM, append it
      if (!document.getElementById(refId)) head.appendChild(styleElem)

      // Set <style> attributes
      styleElem.type = 'text/css'
      styleElem.id = refId
      // Set the content of <style>
      styleElem.innerText = css
    }
  }
}
